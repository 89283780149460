"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GeogroupNewsPost = void 0;
class GeogroupNewsPost {
  constructor(id, geogroup, creator, created, text, photo, link, _publicationDate, type, likesCount, liked) {
    this.id = id;
    this.geogroup = geogroup;
    this.creator = creator;
    this.created = created;
    this.text = text;
    this.photo = photo;
    this.link = link;
    this._publicationDate = _publicationDate;
    this.type = type;
    this.likesCount = likesCount;
    this.liked = liked;
  }
  get publicationDate() {
    return this._publicationDate || this.created;
  }
}
exports.GeogroupNewsPost = GeogroupNewsPost;