"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lastYear = exports.last7Days = exports.last30Days = exports.last12Months = exports.currentYear = exports.currentWeek = exports.currentMonth = exports.Period = void 0;
exports.periodToParams = periodToParams;
exports.prevWeek = exports.prevMonth = exports.periodTypesMap = exports.periodTypes = void 0;
exports.setLocales = setLocales;
exports.today = void 0;
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const periodTypesMap = exports.periodTypesMap = {
  allTime: {
    titleKey: 'commons.periods.all_time'
  },
  week: {
    titleKey: 'commons.periods.week',
    alternativeTitleKey: 'commons.periods.this_week',
    enableInDashboard: true
  },
  month: {
    titleKey: 'commons.periods.month',
    alternativeTitleKey: 'commons.periods.this_month',
    enableInDashboard: true
  },
  year: {
    titleKey: 'commons.periods.year',
    alternativeTitleKey: 'commons.periods.this_year',
    enableInDashboard: true
  },
  today: {
    titleKey: 'commons.periods.today'
  },
  '7days': {
    titleKey: 'commons.periods.7days'
  },
  '30days': {
    titleKey: 'commons.periods.30days'
  },
  '12months': {
    titleKey: 'commons.periods.12months'
  },
  custom: {
    titleKey: 'commons.periods.custom'
  }
};
const periodTypes = exports.periodTypes = [{
  key: 'allTime',
  ...periodTypesMap.allTime
}, {
  key: 'week',
  ...periodTypesMap.week
}, {
  key: 'month',
  ...periodTypesMap.month
}, {
  key: 'year',
  ...periodTypesMap.year
}, {
  key: 'today',
  ...periodTypesMap.today
}, {
  key: '7days',
  ...periodTypesMap['7days']
}, {
  key: '30days',
  ...periodTypesMap['30days']
}, {
  key: '12months',
  ...periodTypesMap['12months']
}, {
  key: 'custom',
  ...periodTypesMap.custom
}];
const now = (0, _moment.default)().endOf('day');
class Period {
  constructor(type, from, to) {
    this.type = type;
    this.from = from;
    this.to = to;
    this.setTitle();
  }
  setTitle() {
    switch (this.type) {
      case 'allTime':
        this.titleKey = 'commons.periods.all_time';
        break;
      case 'week':
        this.titleKey = 'commons.periods.week_number';
        this.alternativeTitleKey = 'commons.periods.this_week';
        break;
      case 'month':
        this.title = this.from.format('MMMM YYYY');
        this.alternativeTitleKey = 'commons.periods.this_month';
        break;
      case 'year':
        this.title = this.from.format('YYYY');
        this.alternativeTitleKey = 'commons.periods.this_year';
        break;
      case 'today':
        this.titleKey = 'commons.periods.today';
        break;
      case '7days':
        this.titleKey = 'commons.periods.7days';
        break;
      case '30days':
        this.titleKey = 'commons.periods.30days';
        break;
      case '12months':
        this.titleKey = 'commons.periods.12months';
        break;
      case 'custom':
        this.titleKey = 'commons.periods.from_to';
        break;
      default:
        break;
    }
  }
  get nbDays() {
    return Math.ceil(this.to.diff(this.from, 'days', true));
  }
  get nbSpentDays() {
    if (this.from.isAfter(now)) return 0;
    if (this.to.isAfter(now)) return Math.ceil(now.diff(this.from, 'days', true));
    return Math.ceil(this.to.diff(this.from, 'days', true));
  }
  get unit() {
    switch (this.type) {
      case 'week':
      case 'month':
      case 'today':
      case '7days':
      case '30days':
        return 'day';
      case 'year':
      case '12months':
        return 'month';
      default:
        return this.to.diff(this.from, 'months') >= 2 ? 'month' : 'day';
    }
  }
  get times() {
    const _times = [];
    const date = (0, _moment.default)(this.from.valueOf());
    _times.push(date.valueOf());
    if (this.type === 'year' || this.type === '12months') {
      for (let i = 0; i < 12; ++i) {
        date.add(1, 'months');
        _times.push(date.valueOf());
      }
    } else {
      for (let i = 0; i < this.nbDays; ++i) {
        date.add(1, 'days');
        _times.push(date.valueOf());
      }
    }
    return _times;
  }
  get hasNext() {
    return this.to.isBefore((0, _moment.default)());
  }
  getPrevPeriod() {
    switch (this.type) {
      case 'week':
        return new Period('week', (0, _moment.default)(this.from).add(-7, 'days').startOf('isoWeek'), (0, _moment.default)(this.from).add(-7, 'days').endOf('isoWeek'));
      case 'month':
        return new Period('month', (0, _moment.default)(this.from).add(-1, 'months').startOf('month'), (0, _moment.default)(this.from).add(-1, 'months').endOf('month'));
      case 'year':
        return new Period('year', (0, _moment.default)(this.from).add(-1, 'years').startOf('year'), (0, _moment.default)(this.from).add(-1, 'years').endOf('year'));
      case 'custom':
      case '30days':
      case '7days':
        return new Period('custom', (0, _moment.default)(this.from).add(-this.nbDays, 'days').startOf('day'), (0, _moment.default)(this.to).add(-this.nbDays, 'days').endOf('day'));
      default:
        throw new Error('prev period not defined');
    }
  }
  getNextPeriod() {
    switch (this.type) {
      case 'week':
        return new Period('week', (0, _moment.default)(this.from).add(7, 'days').startOf('isoWeek'), (0, _moment.default)(this.from).add(7, 'days').endOf('isoWeek'));
      case 'month':
        return new Period('month', (0, _moment.default)(this.from).add(1, 'months').startOf('month'), (0, _moment.default)(this.from).add(1, 'months').endOf('month'));
      case 'year':
        return new Period('year', (0, _moment.default)(this.from).add(1, 'years').startOf('year'), (0, _moment.default)(this.from).add(1, 'years').endOf('year'));
      case 'custom':
      case '30days':
      case '7days':
        return new Period('custom', (0, _moment.default)(this.from).add(this.nbDays, 'days').startOf('day'), (0, _moment.default)(this.to).add(this.nbDays, 'days').endOf('day'));
      default:
        throw new Error('prev period not defined');
    }
  }
  toIPeriod() {
    return {
      period: 'custom',
      from: this.from,
      to: this.to,
      unit: this.unit
    };
  }
  clone() {
    return new Period(this.type, this.from.clone(), this.to.clone());
  }
  locale(locale) {
    this.from.locale(locale);
    this.to.locale(locale);
    this.setTitle();
    return this.clone();
  }
}
exports.Period = Period;
const today = exports.today = new Period('today', (0, _moment.default)().startOf('day'), (0, _moment.default)());
const currentWeek = exports.currentWeek = new Period('week', (0, _moment.default)().startOf('isoWeek'), (0, _moment.default)().endOf('isoWeek'));
const prevWeek = exports.prevWeek = new Period('week', (0, _moment.default)().add(-1, 'week').startOf('isoWeek'), (0, _moment.default)().add(-1, 'week').endOf('isoWeek'));
const currentMonth = exports.currentMonth = new Period('month', (0, _moment.default)().startOf('month'), (0, _moment.default)().endOf('month'));
const prevMonth = exports.prevMonth = new Period('month', (0, _moment.default)().add(-1, 'month').startOf('month'), (0, _moment.default)().add(-1, 'month').endOf('month'));
const currentYear = exports.currentYear = new Period('year', (0, _moment.default)().startOf('year'), (0, _moment.default)().endOf('year'));
const lastYear = exports.lastYear = new Period('year', (0, _moment.default)().add(-1, 'year').startOf('year'), (0, _moment.default)().add(-1, 'year').endOf('year'));
const last7Days = exports.last7Days = new Period('7days', (0, _moment.default)().add(-7, 'days').startOf('day'), (0, _moment.default)());
const last30Days = exports.last30Days = new Period('30days', (0, _moment.default)().add(-30, 'days').startOf('day'), (0, _moment.default)());
const last12Months = exports.last12Months = new Period('12months', (0, _moment.default)().add(-12, 'months').startOf('day'), (0, _moment.default)());
function setLocales(locale) {
  today.locale(locale);
  currentWeek.locale(locale);
  prevWeek.locale(locale);
  currentMonth.locale(locale);
  prevMonth.locale(locale);
  currentYear.locale(locale);
  last7Days.locale(locale);
  last30Days.locale(locale);
  last12Months.locale(locale);
}
function periodToParams(_ref) {
  let {
    period,
    from,
    to,
    unit
  } = _ref;
  return [{
    key: 'period',
    value: period
  }, {
    key: 'date_start',
    value: from.format('DD-MM-YYYY')
  }, {
    key: 'date_end',
    value: to.format('DD-MM-YYYY')
  }, {
    key: 'unit',
    value: unit
  }];
}