"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useUnits = useUnits;
var _moment = _interopRequireDefault(require("moment"));
var _reactI18next = require("react-i18next");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const thousandsSeparators = {
  en: ',',
  fr: ' ',
  de: ' ',
  es: '.'
};
const decimalSeparators = {
  en: '.',
  fr: ',',
  de: ',',
  es: ','
};
function useUnits() {
  const {
    i18n,
    t
  } = (0, _reactI18next.useTranslation)();
  function formatNumber(value) {
    return (value || 0).toString().replace(/\./, decimalSeparators[i18n.language] || ' ').replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparators[i18n.language] || ',');
  }
  function toDistance(_value) {
    let round = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    let unit = arguments.length > 2 ? arguments[2] : undefined;
    if (_value === null) return '-';
    const value = typeof _value === 'number' ? _value : parseInt(_value);
    if (value < 10 && unit !== 'km') return `${Math.round(value)}\u00A0m`;
    if (!round) {
      if (value < 1000 && unit !== 'km') return `${formatNumber(Math.round(value / 50) * 50)}\u00A0m`;
      if (value < 100000) return `${formatNumber(Math.round(value / 100) / 10)}\u00A0km`;
    }
    return `${formatNumber(Math.round(value / 1000))}\u00A0km`;
  }
  function toDistanceWithMeters(_value) {
    if (_value === null) return '-';
    const value = typeof _value === 'number' ? _value : parseInt(_value);
    return `${formatNumber(Math.round(value * 1000) / 1000000)}\u00A0km`;
  }
  function toPercentage(_value) {
    let multiplier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
    if (_value === null) return '-';
    const value = typeof _value === 'number' ? _value : parseInt(_value);
    return `${formatNumber(Math.round(value * multiplier))}%`;
  }
  function toPrice(_value) {
    if (_value === null) return '-';
    const value = typeof _value === 'number' ? _value : parseFloat(_value);
    return `${formatNumber(Math.round(value * 100) / 100)}\u00A0€`;
  }
  function toSpeed(_value) {
    if (_value === null) return '-';
    const value = typeof _value === 'number' ? _value : parseInt(_value);
    return `${formatNumber(Math.round(value * 10) / 10)}\u00A0km/h`;
  }
  function toTime(_value) {
    let unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'auto';
    let enableDays = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    let detailed = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    if (_value === null) return '-';
    if (unit === 'ms') return `${Math.round(typeof _value === 'number' ? _value : parseInt(_value))}\u00A0ms`;
    const value = typeof _value === 'number' ? _value : parseInt(_value);
    const minutes = Math.round(value % 3600 / 60);
    const secondes = Math.round(value % 3600 % 60);
    if (value >= 86400 && enableDays) {
      return t('commons.day', {
        count: Math.round(value / 86400)
      });
    } else if (value >= 3600) {
      const hours = Math.floor(value / 3600);
      if (minutes === 0) return `${hours}\u00A0h`;
      if (minutes === 60) return `${hours + 1}\u00A0h`;
      return `${hours}\u00A0h\u00A0${minutes}\u00A0min`;
    } else if (value >= 60) {
      if (detailed) return `${minutes}\u00A0min\u00A0${secondes}\u00A0sec`;
      return `${minutes}\u00A0min`;
    }
    return `${Math.round(value)}\u00A0sec`;
  }
  function toOrdinal(value) {
    let {
      feminine,
      inclusive
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return i18n.language === 'fr' && value === 1 ? feminine ? '1ère' : inclusive ? 'en première position' : '1er' : _moment.default.localeData(i18n.language).ordinal(value);
  }
  return {
    formatNumber,
    toDistance,
    toDistanceWithMeters,
    toPercentage,
    toPrice,
    toSpeed,
    toTime,
    toOrdinal
  };
}